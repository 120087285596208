html,
body,
:root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: white;
  background-color: black;
  // overflow: hidden;
}

div,
p {
  font-size: 24px;
}

.centeredFlexCol {
  display: flex;
  flex-direction: column;
}

@mixin top {
  z-index: 2;
}

@mixin interactive {
  pointer-events: auto !important;
}
// Roboto weight 700, Lato wieght 300 or

h1 {
  // font-family: 'Lato', sans-serif;
  font-family: 'Roboto Mono', monospace;
  font-size: 100px;
  margin:  0;
}

h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 35px;
  margin: 6px 0;
}

.shadowBox {
  background-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 50px 50px rgba(0,0,0,0.35)
}

@media screen and (max-width: 670px) {
  
    h1{
      font-size: 45px;
    }
}