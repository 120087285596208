html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.webgl {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
}
