.viewSection {
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  border: 2px solid red;
  // overflow-y: hidden;
}

.fillParent {
  // overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.textDisplayContainer {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


