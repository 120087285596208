.container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 10px solid blue;
}

.scrollToAdvance {
    // border: 10px solid red;
    margin-top: 10%;
    // height: 100%;
    display: 'flex';

    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-25%);
    transition: opacity 100ms, transform 100ms

}

.scrollHeader {
    font-size: 75px;
    text-align: center;
}

$inDuration: 700ms;
$outDuration: 250ms;


.active {
    transform: translateY(0);
    opacity: 1;
    transition: opacity $outDuration, transform $outDuration;
}

.deactivated {
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity $outDuration, transform $outDuration;
}

@media screen and (max-width: 670px) {
  
    .scrollHeader{
      font-size: 45px;
    }
}